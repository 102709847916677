import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const base = {
    en: {
        home: 'Home',
        aspects: 'We are...',
        about: 'About',
        agenda: 'Agenda 2028',
        news: 'News',
        teams: 'Teams',
        workouts: 'Practice',
        contact: 'Become a member',
        sponsors: 'Sponsors',
        supporters: 'Supporters',
        partners: 'Partners',
        executives: 'Executives',
        backOffice: 'Back Office',
        medicalSupport: 'Medical Support',
        youth: 'Youth',
        women: 'Women',
        men: 'Men',
        proWomen: 'Pro Women',
        proMen: 'Pro Men',
        imprint: 'Imprint',
        privacy: 'Privacy',
        readMore: 'Read more',
        since: 'since',
        coach: 'Coach',
        coaches: 'coaches',
        facilities: 'Training Facilities',
        memberSince: 'At KTT since',
        weekDays: {
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            sunday: 'Sunday'
        },
        document: 'Documents',
    },
    de: {
        home: 'Home',
        about: 'Der Verein',
        agenda: 'Agenda 2028',
        aspects: 'Wir sind...',
        news: 'News',
        teams: 'Teams',
        workouts: 'Training',
        contact: 'Mitglied werden',
        lang: 'Sprache',
        sponsors: 'Sponsoren',
        supporters: 'Unterstützer',
        partners: 'Partner',
        executives: 'Vorstand',
        medicalSupport: 'ärztliche Betreuung',
        backOffice: 'Back-Office',
        youth: 'Nachwuchs',
        women: 'Liga Damen',
        men: 'Liga Herren',
        proWomen: 'Topteam Damen',
        proMen: 'Topteam Herren',
        imprint: 'Impressum',
        privacy: 'Datenschutz',
        readMore: 'Lies mehr',
        since: 'seit',
        coach: 'Coach',
        coaches: 'Coaches',
        facilities: 'Trainingsstätten',
        memberSince: 'Im KTT seit',
        weekDays: {
            monday: 'Montag',
            tuesday: 'Dienstag',
            wednesday: 'Mittwoch',
            thursday: 'Donnerstag',
            friday: 'Freitag',
            saturday: 'Samstag',
            sunday: 'Sonntag'
        },
        document: 'Dokumente'
    },
    es: {
        // TODO Übersetzung einfügen
        home: 'Missing',
        aspects: 'Missing',
        about: 'Sobre KTT',
        news: 'Noticias',
        teams: 'Equipos',
        workouts: 'Práctica',
        contact: 'Contacto',
        sponsors: 'Patrocinadores',
        partners: 'MISSING',
        supporters: 'MISSSING',
        // TODO Übersetzung einfügen
        executives: 'Junta',
        youth: 'Atletas jòvenes',
        women: 'Missing',
        men: 'Missing',
        proWomen: 'Missing',
        proMen: 'Missing',
        imprint: 'Imprimir',
        readMore: 'Leer mas',
        since: 'desde',
        coach: 'Entrenador',
        coaches: 'Entrenadores',
        facilities: 'Facilidades de entrenamiento',
        memberSince: 'En KTT desde',
        weekDays: {
            monday: 'Lunes',
            tuesday: 'Martes',
            wednesday: 'Miercoles',
            thursday: 'Jueves',
            friday: 'Viernes',
            saturday: 'Sabado',
            sunday: 'Domingo'
        },
        document: 'MISSING'
    }
}


const messages = {
    'en': {
        toolbar: {
            toolBarTitle: 'COLOGNE TRIATHLON TEAM 01',
            home: base.en.home,
            about: base.en.about,
            news: base.en.news,
            teams: base.en.teams,
            youth: base.en.youth,
            workouts: base.en.workouts,
            contact: base.en.contact,
            sponsors: base.en.sponsors,
            agenda: base.en.agenda,
            lang: 'Language',
        },
        timeline: {
            about: base.en.about,
            aspects: base.en.aspects,
            partners: base.en.partners,
            news: base.en.news,
            contact: base.en.contact,
            executives: base.en.executives,
            workouts: base.en.workouts,
            facilities: base.en.facilities,
            coaches: base.en.coaches,
            youth: base.en.youth,
            women: base.en.women,
            men: base.en.men,
            proWomen: base.en.proWomen,
            proMen: base.en.proMen,
            document: base.en.document,
            mission: 'Mission',
            download: 'Download',
            medic: 'Medical Care'
        },
        footer: {
            home: base.en.home,
            about: base.en.about,
            news: base.en.news,
            teams: base.en.teams,
            contact: base.en.contact,
            workouts: base.en.workouts,
            imprint: base.en.imprint,
            privacy: base.en.privacy
        },
        home: {
            about: base.en.about,
            aspects: base.de.aspects,
            readMore: base.en.readMore,
            togetherWith: 'Supported by',
            readMoreSponsors: 'get to know our Partners',
            news: base.en.news,
            contact: base.en.contact
        },
        sponsors: {
            sponsors: base.en.sponsors,
            supporters: base.en.supporters,
            partners: base.en.partners,
            since: base.en.since,
            readMore: 'Read more',
            mainSponsor: 'Main Sponsor',

        },
        about: {
            about: base.en.about,
            executives: base.en.executives,
            backOffice: base.en.backOffice,
            medicalSupport: base.en.medicalSupport,
            firstChairman: '1.Chairman',
            secondChairman: '2.Chairman',
            treasurer: 'Treasurer',
            medic: 'Medical Care',
        },
        agenda: {
            agenda: base.en.agenda,
        },
        youth: {
            youth: base.en.youth,
            mission: 'Our Mission',
            download: 'You can download the Youth Concept here (only German Version aviable):',
            text: 'Since our foundation working with young talents became a central trait of our club. We collected all the knowledge we have gained in almost 20 years in one concept. This concept should be more than just a guideline on how to train young athletes in a sports club.'
        },
        news: {
            news: `KTT ${base.en.news}`,
            readMore: base.en.readMore,
            togetherWith: 'with'
        },
        teams: {
            teams: base.en.teams,
            youth: base.en.youth,
            women: base.en.women,
            men: base.en.men,
            proWomen: base.en.proWomen,
            proMen: base.en.proMen,
            text: ' Athletes compete for the KTT in 8 different leagues.'
        },
        athlete: {
            yearOfBirth: 'Year of Birth',
            nationality: 'Nationality',
            placeOfResidence: 'Place of Residence',
            memberSince: base.en.memberSince,
            strength: 'strength',
            weaks: 'weaks',
            triathlonSince: 'Triathlon since',
            biggestEvent: 'Biggest Event until now',
            goals: 'Goals',
            roleModel: 'Role Models',
            workout: 'Favourite Workout',
            wish: 'Wish for the Future',
            lucky: 'I feel lucky, when'
        },
        workout: {
            workout: base.en.workouts,
            schedule: 'Practice Schedule',
            weekDays: base.en.weekDays,
            coach: base.en.coach,
            coaches: base.en.coaches,
            facilities: base.en.facilities,
            memberSince: base.en.memberSince,
            coachSince: 'Coach since',
            license: 'License',
            Schwimmen: 'Swim Session',
            Radfahren: 'Bike Ride',
            Laufen: 'Running Session',
            Athletik: 'Athletics',
            Yoga: 'Yoga',
            Zwift: 'Zwift',
            CrossTriathlon: 'Cross-Triathlon',
            Mountainbike: 'Mountainbike',
            Trainingsplaene: 'Training-Plan',
            Triathlon: 'Triathlon',
            Nachwuchs: 'Youth',
            close: 'Close',

        },
        executives: {
            Vorstand: 'Association Board',
            Mitgliederverwaltung: 'Member Management',
            'Social Media': 'Social Media',
            'Ansprechperson Sponsoren': 'Contact Person Sponsors',
            'Back Office': 'Back Office',
            Ligaplanung: 'League Planning',
        },
        imprint: {
            imprint: base.en.imprint,
            privacy: base.en.privacy
        },
        contact: {
            contact: base.en.contact,
            contactText: 'Do you have any questions? Would you like us to tell you more about our club or do you even want to join us? Just send us a message.',
            contactForm: 'Contact us',
            firstName: 'first Name',
            lastName: 'last Name',
            email: 'E-Mail Adress',
            subject: 'Subject',
            message: 'Message',
            messageHint: 'Write something',
            success: 'Your Message has been send.',
            error: 'Something went wrong. Please try again later.',
            none: '',
            btn: 'Send Message',
            document: base.en.document,
            documentText: 'Here you will find all important forms and documents to read and download.'
        }

    },
    'de': {
        toolbar: {
            toolBarTitle: 'KÖLNER TRIATHLON TEAM 01',
            home: base.de.home,
            about: base.de.about,
            aspects: base.de.aspects,
            news: base.de.news,
            teams: base.de.teams,
            youth: base.de.youth,
            workouts: base.de.workouts,
            contact: base.de.contact,
            sponsors: base.de.sponsors,
            agenda: base.de.agenda,
            lang: 'Sprache',
        },
        timeline: {
            about: base.de.about,
            aspects: base.de.aspects,
            partners: base.de.partners,
            news: base.de.news,
            contact: base.de.contact,
            executives: base.de.executives,
            workouts: base.de.workouts,
            facilities: base.de.facilities,
            coaches: base.de.coaches,
            youth: base.de.youth,
            women: base.de.women,
            men: base.de.men,
            proWomen: base.de.proWomen,
            proMen: base.de.proMen,
            document: base.de.document,
            mission: 'Mission',
            download: 'Download',
            medic: 'Ärztliche Betreuung'
        },
        footer: {
            home: base.de.home,
            about: base.de.about,
            news: base.de.news,
            teams: base.de.teams,
            contact: base.de.contact,
            workouts: base.de.workouts,
            imprint: base.de.imprint,
            privacy: base.de.privacy
        },
        home: {
            about: base.de.about,
            readMore: base.de.readMore,
            togetherWith: 'Jemeinsam mit',
            readMoreSponsors: 'Mehr über unsere Partner',
            news: base.de.news,
            contact: base.de.contact
        },
        sponsors: {
            sponsors: base.de.sponsors,
            supporters: base.de.supporters,
            partners: base.de.partners,
            since: base.de.since,
            readMore: 'Erfahre mehr',
            mainSponsor: 'Hauptsponsor',
        },
        about: {
            about: base.de.about,
            executives: base.de.executives,
            backOffice: base.de.backOffice,
            medicalSupport: base.de.medicalSupport,
            firstChairman: '1.Vorsitzender',
            secondChairman: '2.Vorsitzender',
            treasurer: 'Kassenwart',
            medic: 'ärztliche Betreuung'
        },
        agenda: {
            agenda: base.de.agenda,
        },
        youth: {
            youth: base.de.youth,
            mission: 'Unsere Mission',
            download: 'Das Nachwuchskonzept zum herunterladen:',
            text: 'Seit der Vereinsgründung ist die Nachwuchsarbeit ein zentraler Pfeiler unserer Aktivitäten. Die in den knapp 20 Jahren gewonnenen Erfahrungen haben wir in einem Nachwuchskonzept zusammengefasst, das mehr als eine bloßer Leitfaden für das Training junger AthletInnen in einem Sportverein sein soll.'


        },
        news: {
            news: `KTT ${base.de.news}`,
            readMore: base.de.readMore,
            togetherWith: 'mit'
        },
        teams: {
            teams: base.de.teams,
            youth: base.de.youth,
            women: base.de.women,
            men: base.de.men,
            proWomen: base.de.proWomen,
            proMen: base.de.proMen,
            text: ' Athletinnen und Athleten starten für das KTT in 8 verschiedenen Ligen.'
        },
        athlete: {
            yearOfBirth: 'Jahrgang',
            nationality: 'Nationalität',
            placeOfResidence: 'Wohnort',
            memberSince: base.de.memberSince,
            strength: 'Stärken',
            weaks: 'Schwächen',
            triathlonSince: 'Triathlon seit',
            biggestEvent: 'Größtes Event bis jetzt',
            goals: 'Ziele',
            roleModel: 'Vorbilder',
            workout: 'Lieblingseinheit',
            wish: 'Wunsch für die Zukunft',
            lucky: 'Ich bin glücklich, wenn'
        },
        workout: {
            workout: base.de.workouts,
            schedule: 'Trainingszeiten',
            weekDays: base.de.weekDays,
            coach: base.de.coach,
            coaches: base.de.coaches,
            facilities: 'Trainingsstätten',
            memberSince: base.de.memberSince,
            coachSince: 'Trainer/in seit',
            license: 'Lizenz',
            Schwimmen: 'Schwimmen',
            Radfahren: 'Radfahren',
            Laufen: 'Laufen',
            Athletik: 'Athletik',
            Yoga: 'Yoga',
            Zwift: 'Zwift',
            CrossTriathlon: 'Cross-Triathlon',
            Mountainbike: 'Mountainbike',
            Trainingsplaene: 'Trainingspläne',
            Triathlon: 'Triathlon',
            Nachwuchs: 'Nachwuchs',
            close: 'Schließen'
        },
        imprint: {
            imprint: base.de.imprint,
            privacy: base.de.privacy
        },
        executives: {
            Vorstand: 'Vorstand',
            Mitgliederverwaltung: 'Mitgliederverwaltung',
            'Social Media': 'Social Media',
            'Ansprechperson Sponsoren': 'Ansprechperson Sponsoren',
            'Back Office': 'Back Office',
            Ligaplanung: 'Ligaplanung',
        },
        contact: {
            contact: base.de.contact,
            contactText: 'Hast du Fragen oder Anregungen? Möchtest du mehr über unseren Verein erfahren oder uns vielleicht beitreten? Schreib uns einfach eine Nachricht.',
            contactForm: 'Kontaktiere uns',
            firstName: 'Vorname',
            lastName: 'Nachname',
            email: 'E-Mail Adresse',
            subject: 'Betreff',
            message: 'Nachricht',
            messageHint: 'Schreib etwas',
            success: 'Deine Nachricht wurde versandt.',
            error: 'Etwas ist schiefgelaufen. Bitte versuche es später nochmal.',
            none: '',
            btn: 'Nachricht absenden',
            document: base.de.document,
            documentText: 'Hier findest du alle wichtigen Formulare und Dokumente zum Nachlesen und herunterladen.'
        }
    },
    'es': {
        toolbar: {
            toolBarTitle: 'Equipo de triatlón de Colonia 01',
            home: base.es.home,
            about: base.es.about,
            news: base.es.news,
            teams: base.es.teams,
            youth: base.es.youth,
            workouts: base.es.workouts,
            contact: base.es.contact,
            sponsors: base.es.sponsors,
            lang: 'lengua',
        },
        timeline: {
            about: base.es.about,
            aspects: base.es.aspects,
            partners: base.es.partners,
            news: base.es.news,
            contact: base.es.contact,
            executives: base.es.executives,
            workouts: base.es.workouts,
            facilities: base.es.facilities,
            coaches: base.es.coaches,
            youth: base.es.youth,
            women: base.es.women,
            men: base.es.men,
            proWomen: base.es.proWomen,
            proMen: base.es.proMen,
            document: base.es.document,
            mission: 'Missing',
            download: 'Missing',
            medic: 'Missing'
        },
        footer: {
            home: base.es.home,
            about: base.es.about,
            teams: base.es.teams,
            news: base.es.news,
            contact: base.es.contact,
            workouts: base.es.workouts,
            imprint: base.es.imprint
        },
        home: {
            about: base.es.about,
            aspects: base.de.aspects,
            readMore: base.es.readMore,
            togetherWith: 'Apoyado por',
            readMoreSponsors: 'Conoce a nuestros patrocinadores',
            news: base.es.news,
            contact: base.es.contact
        },
        sponsors: {
            sponsors: base.es.sponsors,
            supporters: base.es.supporters,
            partners: base.es.partners,
            since: base.es.since,
            readMore: 'Erfahre mehr',
            mainSponsor: 'Hauptsponsor',
        },
        about: {
            about: base.es.about,
            executives: base.es.executives,
            firstChairman: '1er Presidente',
            secondChairman: '2do Presidente',
            treasurer: 'Tesorero',
            medic: 'MIssing'
        },
        youth: {
            youth: base.es.youth,
            mission: 'MISSING',
            download: 'MISSING'
        },
        news: {
            news: `${base.es.news} de KTT`,
            readMore: base.es.readMore,
            togetherWith: 'con'
        },
        teams: {
            teams: base.es.teams,
            youth: base.es.youth,
            women: base.es.women,
            men: base.es.men,
            proWomen: base.es.proWomen,
            proMen: base.es.proMen,
            text: 'MISSING'
        },
        // TODO Übersetzung einfügen
        athlete: {
            yearOfBirth: 'Year of Birth',
            nationality: 'Nationality',
            placeOfResidence: 'Place of Residence',
            memberSince: base.es.memberSince,
            strength: 'strength',
            weaks: 'weaks',
            triathlonSince: 'Triathlon since',
            biggestEvent: 'Biggest Event until now',
            goals: 'Goals',
            roleModel: 'Role Models',
            workout: 'Favourite Workout',
            wish: 'Wish for the Future',
            lucky: 'I feel lucky, when'
        },
        workout: {
            workout: base.es.workouts,
            schedule: 'Practice Schedule',
            weekDays: base.es.weekDays,
            coach: base.es.coach,
            coaches: base.es.coaches,
            facilities: base.es.facilities,
            memberSince: base.es.memberSince,
            coachSince: 'Entrenador desde',
            license: 'Licencia',
            motivation: 'Motivación',
            swim: 'MISSING',
            bike: 'MISSING MISSING',
            run: 'MISSING',
            triathlon: 'MISSING',
            athletics: 'MISSING',
            yoga: 'MISSING',
            zwift: 'Zwift'
        },
        imprint: {
            imprint: base.es.imprint
        },
        contact: {
            contact: base.es.contact,
            //TODO Übersetzung einfügen
            contactText: 'MISSING',
            name: 'MISSING',
            email: 'MISSING',
            subject: 'MISSING',
            message: 'MISSING',
            success: 'MISSING',
            error: 'MISSING',
            btn: 'MISSING',
            document: base.es.document,
            documentText: 'MISSING'
        }


    }
}


const i18n = new VueI18n({
    locale: 'de',
    fallbackLocale: 'en',
    messages
})

export default i18n
