<template>
  <v-footer
      class="mt-10 pa-0"
      color="transparent"
  >
    <v-container
        fluid
    >
      <v-row align="center" justify="center">
        <v-col
            v-for="(sponsor, i) in sponsors"
            :key="i"
            class="d-flex justify-center"
            cols="6"
            sm="3"
            lg="2"
        >
          <!--router-link to="/sponsors"-->
          <router-link to='/sponsors'
          >
            <v-img
                :src="sponsor.logo.url"
                max-width="120"
            >
            </v-img>
          </router-link>
        </v-col>
      </v-row>
      <v-row align="center" justify="space-around"  dense style="margin:0" class="mt-3">
        <v-col
            v-for="logo in footerLogos"
            :key="logo.sys.id"
            class="mx-2 d-flex align-center align-content-center"
            cols="5"
            sm="2"
            lg="1"

        >
          <a :href="logo.description" target="_blank">
            <v-img
                :src="logo.url"
                max-width="120"
            >
            </v-img>
          </a>
        </v-col>
      </v-row>
    </v-container>
    <v-container
        fluid
        style="background-color: rgba(0,0,0,0.9)"
    >
      <v-row
          justify="center"
          class="my-3"
      >
        <v-btn
            v-for="(social, i) in socials"
            :key="`s-${i}`"
            :href="social[0]"
            target="_blank"
            rel="noopener"
            text
            class="px-0 mx-2"
            min-width="70"
        >
          <v-icon
              color="white"
              size="30"
              v-text="social[1]"
          />
        </v-btn>
      </v-row>
      <v-row
          justify="center"
          class="my-3"
      >
        <v-btn
            :to="imprint[0]"
            class="px-1 mx-2 logo"
            text
        >
          {{$t(`footer.${imprint[1]}`)}}
        </v-btn>
        <v-btn
            :to="privacy[0]"
            class="px-1 mx-2 logo"
            text
        >
          {{$t(`footer.${privacy[1]}`)}}
        </v-btn>
      </v-row>
    </v-container>
    <v-container
        fluid
        class="rainbow"
    >
      <v-row
          justify="center"
      >
        <v-card-title
            id="about"
            class="logo font-weight-bold text-h5 mt-1 black--text"
        >
          #LoveIsLove
        </v-card-title>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import {fetchData, query_FOOTER_LOGOS, query_SPONSORIMAGES} from "@/utils/api"
import {mapState} from 'vuex'
import i18n from "@/plugins/i18n"

export default {
  name: 'Footer',
  data: () => ({
    socials: [
      ['https://www.facebook.com/KTT2001/', 'mdi-facebook'],
      ['https://www.instagram.com/ktt_01/', 'mdi-instagram'],
      ['https://www.youtube.com/watch?v=-YDoFcLOJWU&list=UUDknwcy2ZD48E7CoA9KhTZw', 'mdi-youtube'],
      ['https://www.strava.com/clubs/k%C3%B6lner-triathlon-team-01-604977', 'mdi-strava']
    ],
    imprint:['/imprint', 'imprint'],
    privacy:['/imprint', 'privacy'],
    footerLogos: [],
    sponsors: []
  }),
  methods: {
    getFooterLogos: async () => {
      const query = query_FOOTER_LOGOS(i18n.locale)
      return await fetchData(query, 'assetCollection')
    },
    getSponsors: async () => {
      const query = query_SPONSORIMAGES(i18n.locale)
      return await fetchData(query, 'sponsorCollection')
    }
  },
  computed: {
    ...mapState('app', ['links'])
  },
  async created() {
    this.sponsors = await this.getSponsors()
    this.footerLogos = await this.getFooterLogos()
  }
}
</script>

<style lang="sass">

.rainbow
  background-image: linear-gradient(black 12.5%, brown 12.5% 25%, red 25% 37.5%,orange 37.5% 50%,yellow 50% 62.5%,green 62.5% 75%,blue 75% 87.5%, violet 87.5%)
</style>


