import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home')
    },
    {
      path: '/news',
      name: 'News',
      component: () => import('@/views/News')
    },
    {
      path: '/news/:slug',
      name: 'Post',
      component: () => import('@/views/Post')
    },
    {
      path: '/teams',
      name: 'Teams',
      component: () => import('@/views/Teams')
    },
    {
      path: '/youth',
      name: 'Youth',
      component: () => import('@/views/Youth')
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('@/views/About')
    },
    {
    path: '/agenda',
    name: 'Agenda',
    component: () => import('@/views/Agenda')
    },
    {
      path: '/imprint',
      name: 'Imprint',
      component: () => import('@/views/Imprint')
    },
    {
      path: '/sponsors',
      name: 'Sponsor',
      component: () => import('@/views/Sponsors')
    },
    {
      path: '/athlete/:id',
      name: 'Athlete',
      component: () => import('@/views/Athlete')
    },
    {
      path: '/training',
      name: 'Training',
      component: () => import('@/views/Training')
    },
    {
      path: '/contact',
      name: 'Contact',
      component: () => import('@/views/Contact')
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
